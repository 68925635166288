*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  direction: rtl;
}

html {
  box-sizing: border-box;
  /* 
  @media only screen and (max-width: $bp-largest) {
    font-size: 50%; //1rem = 8px
  } */
}
